import React from "react"

export default function hero() {
  return (
    <section class="relative home-wrapper items-center overflow-hidden">
      <div class="section wf-section">
        <div class="section-container-lg center w-container">
          <div class="section-heading-container">
            <h1 class="heading">
              Hire in Turkey — <br />
              without a Turkish entity.
              <br />
            </h1>
            <p class="lp-text m-auto mt-8">
              <span class="gray">
                Professional Employer Organization in Turkey – PEO Payroll
                Company
                <br />
              </span>
            </p>
            <div class="div-block-403 mt-8">
              <div class="gradient-btn">
                <div class="gradient--btn mailroom">
                  <a
                    href="https://dashboard.launchturkey.com/users/sign_up"
                    class="gradient-button-blue w-button"
                  >
                    Hire employees without stress now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-container-lg w-container">
          <div class="w-layout-grid new-grid">
            <div
              id="w-node-_7578b810-411f-6c7e-ae2c-4553d15b89d9-c9578669"
              class="panel"
            >
              <div class="panel-txt-container justify-center">
                <div>
                  <h2 class="panel-heading">
                    Turkey PEO &amp; Employer of Record
                  </h2>
                  <p class="text">
                    Launch Turkey offers comprehensive employment solutions for
                    companies hiring employees in Turkey. Our Turkey PEO &amp;
                    Employer of Record solutions mean that we take over payroll,
                    tax and employment compliance in Turkey. This leaves you
                    free to focus on expanding your core business, while freeing
                    you from the costly and time-consuming process of setting up
                    a local entity.
                    <br />
                  </p>
                </div>
              </div>
              <div class="panel-img-container">
                <img
                  src="https://res.cloudinary.com/avomeraydin/image/upload/v1677277062/assets/launchturkey/Bursa-View_z2nsbb.jpg"
                  loading="lazy"
                  alt="Payroll Account"
                  class="panel-img contain"
                />
              </div>
            </div>
            <div
              id="w-node-a128f3fd-8fd9-c441-6bbb-f4d40ddb8266-c9578669"
              class="panel"
            >
              <div class="panel-img-container">
                <img
                  src="https://res.cloudinary.com/avomeraydin/image/upload/v1677275317/assets/launchturkey/sea-g4c12e42cc_1920_w1cziq.jpg"
                  loading="lazy"
                  alt="Payroll Account"
                  class="panel-img contain"
                />
              </div>
              <div class="panel-txt-container justify-center">
                <div>
                  <h2 class="panel-heading">
                  Turkish Employment Laws for remote work.
                  </h2>
                  <p class="text">
                  Our professional law firm, providing PEO and EOR services for all your remote teams in Turkey. Employment and employee experience are currently going through a fundamental transformation. Automation through artificial intelligence, data analytics and cloud technology has changed the way organizations employ and manage their workforces. Government policies around hiring and managing a workforce, strengthening labor laws, and a strong focus on compliance are creating huge organizational and HR challenges. Business leaders are now facing unprecedented employment risks and technological disruptions as the pace of these changes continues to accelerate.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://assets.website-files.com/609486d2fafa37698a57db5b/6298d8fb803cc0889127b366_agent-gridbg.svg"
          loading="lazy"
          alt=""
          class="hero-bg saturation _125"
        />
      </div>
    </section>
  )
}
