import * as React from "react"
import Hero from '../components/peo/peohero'
import CTA from "../components/cta"
import Navbar from "../components/navbar"
import Footer from "../components/footer"


import Seo from "../components/seo"

export default function index() {
  return (
  <>
      <Seo title="Launch Turkey" metaDescription="Launch Turkey is help people to launch their own entity in Turkey easily." />
      <body>
        <Navbar />
        <section class="relative home-wrapper items-center overflow-hidden">
        <div class=" us cta wf-section">
                <div class="section-container-lg w-container">
                <div class="call-to-action">
                    <div class="div-block-377">
                    <h2 class="heading-86 mb-6">Click button to download your guide</h2>
                    <div class="cta-btn-container mt-6">
                    <div class="gradient-btn">
                        <div class="gradient--btn">
                            <a
                            href="https://drive.google.com/file/d/1ReFJvg9yBIKp4e1zk8N_Qsh2xTkulzzV/view?usp=sharing"
                            class="cta-bottom-button w-button"
                            target="_blank"
                            >
                            Download
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
        </div>
        </section>
        <Hero />
        <CTA />
        <Footer />
      </body>
  </>
  )
}
